import Vue from 'vue'
import Router from 'vue-router'
const My = () => import(/* webpackChunkName: "group-account" */ '@/view/my/my.vue')//我的账户
const AccountManagement = () => import(/* webpackChunkName: "group-account" */ '@/view/my/accountManagement.vue')//登入账户管理
const PersonalData = () => import(/* webpackChunkName: "group-account" */ '@/view/my/personalData.vue')//个人资料
const Subscribe = () => import(/* webpackChunkName: "group-account" */ '@/view/my/subscribe.vue')//订阅设置
const MessageIndex = () => import(/* webpackChunkName: "group-account" */ '@/view/my/message/messageIndex.vue')//我的消息
const MessageDetails = () => import(/* webpackChunkName: "group-account" */ '@/view/my/message/messageDetails.vue')//我的消息详情
const ProductView = () => import(/* webpackChunkName: "group-production" */ '@/view/product/productView.vue')//我的产品
const ActivateService = () => import(/* webpackChunkName: "group-production" */ '@/view/product/activateService.vue')//激活增值服务
const ProductRegistration = () => import(/* webpackChunkName: "group-production" */ '@/view/product/productRegistration.vue')//产品注册
const AddServices = () => import(/* webpackChunkName: "group-production" */ '@/view/product/addServices.vue')//增值服务
const ProductDetails = () => import(/* webpackChunkName: "group-productDetails" */ '@/view/product/productDetails.vue')//我的产品详情
const Callback = () => import(/* webpackChunkName: "group-login" */ '@/view/login/callback.vue')//我的产品详情
const Silent = () => import(/* webpackChunkName: "group-login" */ '@/view/login/silent.vue')//我的产品详情


Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [{
        path: '/',
        // name: 'my',
        component: My,
        children: [{
            path: '/',
            name: 'accountManagement',
            component: AccountManagement
        }, {
            path: '/personalData',
            name: 'personalData',
            component: PersonalData
        }, {
            path: '/subscribe',
            name: 'subscribe',
            component: Subscribe
        }, {
            path: '/messageIndex',
            name: 'messageIndex',
            component: MessageIndex
        }, {
            path: '/messageDetails',
            name: 'messageDetails',
            component: MessageDetails
        }, {
            path: '/productView',
            name: 'productView',
            component: ProductView
        }, {
            path: '/productDetails',
            name: 'productDetails',
            component: ProductDetails
        }, {
            path: '/activateService',
            name: 'activateService',
            component: ActivateService
        }, {
            path: '/productRegistration',
            name: 'productRegistration',
            component: ProductRegistration
        }, {
            path: '/addServices',
            name: 'addServices',
            component: AddServices
        }
        ]
    },{
        path:'/login/callback',
        component:Callback
    },{
        path:'/login/silent',
        component:Silent
    }]
})

