// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import './static/css/public.css'
import $ from 'jquery'
import axios from 'axios'
import _lodash from 'lodash'
import "@/utils/oidc"
import Promise from 'es6-promise'

Vue.use(ElementUI);
Vue.config.productionTip = false;
Promise.polyfill()

//注册全局方法组件也可以使用
Vue.prototype.$axios = axios;

/* eslint-disable */
Vue.prototype._lodash = _lodash;

new Vue({
    el: '#app',
    router,
    render: h => h(App),
}).$mount('#app')


