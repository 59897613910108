import router from '@/router'
// import Oidc from 'oidc-client'
// import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
export var oidcOptions = {
    authority: process.env.VUE_APP_OIDC_URL,//认证服务器,需要修改为自己的认证服务器
    client_id: 'p2a1d988c4ff6652',//客户端 id ,按照约定修改即可
    post_logout_redirect_uri: process.env.VUE_APP_DOMAIN_URL + '/index.html',//登出回调链接
    redirect_uri: process.env.VUE_APP_DOMAIN_URL + '/login/callback.html',//认证服务器回调的客户端页面
    silent_redirect_uri: process.env.VUE_APP_DOMAIN_URL + '/login/silent.html',//这个是刷新登录 token的回调地址
    accessTokenExpiringNotificationTime: 60,
    automaticSilentRenew: true,
    response_type: 'id_token token',
    scope: 'openid profile CustomerAllApi ProductAllApi',
    filterProtocolClaims: true
};
export var storageKeyName = 'oidc.user:' + process.env.VUE_APP_DOMAIN_URL + ':' + oidcOptions.client_id;
export var userData = JSON.parse(sessionStorage.getItem(storageKeyName));
// console.log(Oidc)
// console.log(new Oidc.UserManager(oidcOptions))
export var manager = new Oidc.UserManager(oidcOptions);
export function getUser(callback) {
    manager.getUser().then(callback);
}
export function isLoginSigninreDirect() {
    getUser(function (user) {
        if (!user) {
            manager.signinRedirect();
        }
    });
}
// 自动刷新Token完成后回调（automaticSilentRenew为true是开启自动刷新）
manager.events.addUserLoaded(function (user) {
    if (!user) {
        manager.signinRedirect();
    }
});
// 自动刷新Token失败后回调
manager.events.addSilentRenewError(function () {
    manager.signinRedirect();
});
//当其它客户端退出登录后，会员中心客户端也要退出
manager.events.addUserSignedOut(function () {
    manager.removeUser();
    manager.signinRedirect();
});
//退出登录
export function logout() {
    manager.signoutRedirect();
}
router.beforeEach((to, from, next) => {
    if (account.login.status) {
        sessionStorage.setItem('pathName', to.fullPath)
        isLoginSigninreDirect();
        next()
    } else {
        manager.signinRedirect();
    }
})