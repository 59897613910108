<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { userData } from "@/utils/oidc";
export default {
    name: 'App',
    mounted() {
        if (userData) {
            if (userData.profile.site_theme == '0') {
                import("./static/css/theme.css");
            } else {
                import("./static/css/theme-black.css");
            }
        }
        let routeName = this.$router.options.routes[0].children;
        let routeArr = [];
        for (var i = 0; i < routeName.length; i++) {
            routeArr.push(routeName[i].path)
        }
        // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
        window.addEventListener('hashchange', () => {
            if (routeArr.indexOf(this.$route.path) == -1) {
                window.location.href = "https://acc-account.asus.com.cn/error"
            }
            // let currentPath = window.location.hash.slice(1);
            // if (routeArr.indexOf(this.$route.path) > -1) {//判断currentPath是否是定义了的路径
            //     // if (this.$route.path !== currentPath) {
            //     //     this.$router.push(currentPath)
            //     // }
            // } else {
            //     console.log(123)
            //     window.location.href = "https://acc-account.asus.com.cn/error"
            // }
        }, false);
    }
}
</script>

<style scoped lang="css" type="text/css">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #181818;
    height: 100%;
}
</style>
